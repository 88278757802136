<template>
  <li>
    <span
      v-for="regimen, index in treatment.regimens"
      :key="`${index}-${regimen.drug.id}-${$i18n.locale}`"
    >
      <a
        v-if="drugLink"
        class="drug_name"
        @click="$emit('drug-click', regimen.drug)"
        v-text="regimen.drug.name[$i18n.locale] || regimen.drug.name.en"
      />
      <span
        v-else
        class="drug_name"
        v-text="regimen.drug.name[$i18n.locale] || regimen.drug.name.en"
      />
      <v-dialog
        v-model="drugInfoDialog"
        color="white"
        :max-width="550"
        bottom
      >
        <!-- <template #activator="{ on }">
          <span
            class="drug_name"
            style="cursor: pointer"
            v-on="on"
          >
            {{ regimen.drug.name.en }}
          </span>
        </template> -->
        <template #activator="{ on }">
          <v-icon
            v-if="showDrugInfo"
            small
            class="mt-n1"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <template #default>
          <v-card xstyle="color:#000">
            <v-card-title>
              {{ regimen.drug.name[$i18n.locale] || regimen.drug.name.en }}
              <v-spacer />
              <v-btn
                icon
                tile
                color="grey"
                @click.stop="drugInfoDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <drug-info
                :drug="regimen.drug"
                :reference-dosage="regimen.standard_dosage"
              />
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-img
        v-if="showArkscore"
        :src="`/img/arkscore-pill-${regimen.drug.score_adverse_reaction}.svg`"
        class="mx-1 mt-n1"
        style="display: inline-block; vertical-align: middle"
        width="90"
        contain
      />
      {{ regimen.dosage_and_duration || `${regimen.dosage} x ${regimen.duration}` }}
      {{ $t(regimen.pivot.connector) }}
    </span>
    <template
      v-if="showIndication"
    >
      {{ $t('for possible', { indication: treatment.indication.indication[this.$i18n.locale] || treatment.indication.indication.en }) }}
    </template>
  </li>
</template>
<script>

export default {
  components: {
    DrugInfo: () => import('@/components/drug/DrugInfo.vue'),
  },
  props: {
    treatment: {
      type: Object,
      default: () => ({}),
    },
    drugLink: {
      type: Boolean,
      default: true,
    },
    showArkscore: Boolean,
    showIndication: Boolean,
    showDrugInfo: Boolean,
  },
  data: () => ({
    drugInfoDialog: false,
  }),
  watch: {
    '$i18n.locale' (lang) {
      if (lang === 'en') {
        this.treatment.regimens.forEach(r => this.$set(r, 'dosage_and_duration', null))
      }
      this.translateText(
        [...this.treatment.regimens].map(r => `${r.dosage} for ${r.duration}`),
        this.$i18n.locale,
        v => v.forEach((v, i) => this.$set(this.treatment.regimens[i], 'dosage_and_duration', v)),
      )
    },
  },
}
</script>
<style>
.drug_name {
  font-weight: 500;
}
a.drug_name {
  text-decoration: underline;
  color: inherit;
}
</style>
